import { allToleName } from '../ToleList';
import { selectedLanguage } from '../institute';
import { toleWardEight, toleWardEighteen, toleWardEleven, toleWardFifteen, toleWardFive, toleWardFour, toleWardFourteen, toleWardNine, toleWardNineteen, toleWardOne, toleWardSeven, toleWardSeventeen, toleWardSix, toleWardSixteen, toleWardTen, toleWardThirteen, toleWardThree, toleWardTwelve, toleWardTwo } from '../ToleList';
export const ToleNameFetchByWard = (toleName, ward) => {
    switch (ward) {
        case 1:
            return toleWardOne.find(i => i.value === toleName) ? toleWardOne.find(i => i.value === toleName)[selectedLanguage] : ""

        case 2:
            return toleWardTwo.find(i => i.value === toleName) ? toleWardTwo.find(i => i.value === toleName)[selectedLanguage] : ""

        case 3:
            return toleWardThree.find(i => i.value === toleName) ? toleWardThree.find(i => i.value === toleName)[selectedLanguage] : ""

        case 4:
            return toleWardFour.find(i => i.value === toleName) ? toleWardFour.find(i => i.value === toleName)[selectedLanguage] : ""

        case 5:
            return toleWardFive.find(i => i.value === toleName) ? toleWardFive.find(i => i.value === toleName)[selectedLanguage] : ""

        case 6:
            return toleWardSix.find(i => i.value === toleName) ? toleWardSix.find(i => i.value === toleName)[selectedLanguage] : ""

        case 7:
            return toleWardSeven.find(i => i.value === toleName) ? toleWardSeven.find(i => i.value === toleName)[selectedLanguage] : ""

        case 8:
            return toleWardEight.find(i => i.value === toleName) ? toleWardEight.find(i => i.value === toleName)[selectedLanguage] : ""

        case 9:
            return toleWardNine.find(i => i.value === toleName) ? toleWardNine.find(i => i.value === toleName)[selectedLanguage] : ""

        case 10:
            return toleWardTen.find(i => i.value === toleName) ? toleWardTen.find(i => i.value === toleName)[selectedLanguage] : ""

        case 11:
            return toleWardEleven.find(i => i.value === toleName) ? toleWardEleven.find(i => i.value === toleName)[selectedLanguage] : ""

        case 12:
            return toleWardTwelve.find(i => i.value === toleName) ? toleWardTwelve.find(i => i.value === toleName)[selectedLanguage] : ""

        case 13:
            return toleWardThirteen.find(i => i.value === toleName) ? toleWardThirteen.find(i => i.value === toleName)[selectedLanguage] : ""

        case 14:
            return toleWardFourteen.find(i => i.value === toleName) ? toleWardFourteen.find(i => i.value === toleName)[selectedLanguage] : ""

        case 15:
            return toleWardFifteen.find(i => i.value === toleName) ? toleWardFifteen.find(i => i.value === toleName)[selectedLanguage] : ""

        case 16:
            return toleWardSixteen.find(i => i.value === toleName) ? toleWardSixteen.find(i => i.value === toleName)[selectedLanguage] : ""

        case 17:
            return toleWardSeventeen.find(i => i.value === toleName) ? toleWardSeventeen.find(i => i.value === toleName)[selectedLanguage] : ""

        case 18:
            return toleWardEighteen.find(i => i.value === toleName) ? toleWardEighteen.find(i => i.value === toleName)[selectedLanguage] : ""

        case 19:
            return toleWardNineteen.find(i => i.value === toleName) ? toleWardNineteen.find(i => i.value === toleName)[selectedLanguage] : ""

        default:
            return "";
    }
}
export const ToleNameFetch = (toleName) => {
    return allToleName.find(i => i.value === toleName) ? allToleName.find(i => i.value === toleName)[selectedLanguage] : ""
}