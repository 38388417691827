import { Button, Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { data } from "jquery";
import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ImageUploader from "../ImageUploader";
import { genderList } from "../../data/advanceSearch";
import { selectedLanguage } from "../../data/institute";
import { fetchStaffById, saveStaff } from "../../modules/Auth/_redux/authCrud";
import axiosHandler from "../../../redux/axiosHandler";
import Loader from "../Loader";
import * as Yup from "yup";

const defaultValues = {
	name: "",
	nameEnglish: "",
	address: "",
	addressEnglish: "",
	phoneNumber: "",
	phoneNumberEnglish: "",
	designation: "",
	designationEnglish: "",
	sakha: "",
	sakhaEnglish: "",
	gender: "",
	email: "",
	id: "",
	workTimeSpanFrom: "",
	workTimeSpanTo: "",
	uniqueIdentifier: "",
	showOnWebsite: "",
	employeeType: "",
};
const EditStaffSchema = Yup.object().shape({
	email: Yup.string().email("ईमेल गलत छ"),
	name: Yup.string().required("पुरा नाम अनिवार्य छ"),
});
const EditStaff = (props) => {
	const [userIdToEdit, SetuserIdToEdit] = useState("");
	const [initialValues, setInitialValues] = useState(defaultValues);
	const [loading, setLoading] = useState(true);
	const { authToken } = useSelector(
		({ auth }) => ({
			authToken: auth?.token,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (props.show) {
			setLoading(true);
			fetchStaffById(props.id)
				.then((res) => {
					setLoading(false);
					const data = res.data;
					const staffDetails = {
						showOnWebsite: data.showOnWebsite === true ? "yes" : "no",
						name: data.name,
						nameEnglish: data.nameEnglish,
						email: data.email,
						phoneNumber: data.phoneNumber,
						phoneNumberEnglish: data.phoneNumberEnglish,
						address: data.address,
						addressEnglish: data.addressEnglish,
						id: data._id,
						designation: data.designation,
						designationEnglish: data.designationEnglish,
						sakha: data.sakha,
						sakhaEnglish: data.sakhaEnglish,
						gender: data.gender,
						workTimeSpanFrom: data.workTimeSpanFrom,
						workTimeSpanTo: data.workTimeSpanTo,
						uniqueIdentifier: data.uniqueIdentifier,
						employeeType: data.employeeType,
					};
					setInitialValues(staffDetails);
				})
				.catch((err) => {
					setLoading(false);
					toast.error("An error occured while fetching user data");
				});
		}
	}, [userIdToEdit, props.show]);
	const formik = useFormik({
		initialValues,
		validationSchema: EditStaffSchema,
		enableReinitialize: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setSubmitting(true);
			saveStaff(values)
				.then((res) => {
					setSubmitting(false);
					if (res.data.success) {
						handleClose();
						toast.success("User details updated successfully");
						window.location.reload();
					} else {
						if (res.data) {
							Object.keys(res.data).map((key) => {
								toast.error(res.data[key]);
							});
						}
					}
				})
				.catch((err) => {
					console.log(err.response);
					setSubmitting(false);
					if (err.response.data) {
						Object.keys(err.response.data).map((key) => {
							toast.error(err.response.data[key]);
						});
					}
				});
		},
	});

	const employeeTypeOption = [
		{
			name: "पुरानो कर्मचारी",
			value: "old_employee",
		},
		{
			name: "हालका कर्मचारी",
			value: "current_employee",
		},
		{
			name: "जन प्रतिनिधि",
			value: "janapratinidhi",
		},
	];

	const handleClose = () => props.handleDeleteClose();
	const getContent = () => {
		const staff = formik.values;
		return (
			<>
				{loading && <Loader loading={loading} />}
				{!loading && (
					<form onSubmit={formik.handleSubmit}>
						<div className="row">
							<div className="col-md-6 mb-4">
								<label className="form-group">गृहपृष्ठमा देखाउनुहोस्</label>
								<div class="radio-inline">
									<label className="radio radio-rounded">
										<input
											type="radio"
											name="showOnWebsite"
											onChange={formik.handleChange}
											checked={staff.showOnWebsite === "yes"}
											value="yes"
										/>
										<span></span>देखाउनु
									</label>
									<label class="radio radio-rounded">
										<input
											type="radio"
											onChange={formik.handleChange}
											name="showOnWebsite"
											checked={staff.showOnWebsite === "no"}
											value="no"
										/>
										<span></span>
										नदेखाउनुहोस्
									</label>
								</div>
								{formik.touched.showOnWebsite && formik.errors.showOnWebsite ? (
									<div class="invalid-feedback">
										{formik.errors.showOnWebsite}
									</div>
								) : null}
							</div>

							<div className="col-md-6 mb-4">
								<div className="form-group">
									<label>कर्मचारी प्रकार</label>
									<select
										name="employeeType"
										className="form-control"
										onChange={formik.handleChange}
										value={staff.employeeType}
									>
										<option disabled>Select an Option</option>
										{employeeTypeOption.map((eachD) => (
											<option value={eachD.value}>{eachD.name}</option>
										))}
									</select>
									{formik.touched.employeeType && formik.errors.employeeType ? (
										<div class="invalid-feedback">
											{formik.errors.employeeType}
										</div>
									) : null}
								</div>
							</div>

							<div className="col-md-6 mb-4">
								<div className="form-group">
									<label>पुरा नाम </label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="name"
										value={staff.name}
									/>
									{formik.touched.name && formik.errors.name ? (
										<div class="invalid-feedback">{formik.errors.name}</div>
									) : null}
								</div>
							</div>
							<div className="col-md-6 mb-4">
								<div className="form-group">
									<label>Full Name</label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="nameEnglish"
										value={staff.nameEnglish}
									/>
									{formik.touched.nameEnglish && formik.errors.nameEnglish ? (
										<div class="invalid-feedback">
											{formik.errors.nameEnglish}
										</div>
									) : null}
								</div>
							</div>

							<div className="col-md-6 mb-4">
								<div className="form-group">
									<label>ठेगाना: </label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="address"
										value={staff.address}
									/>
									{formik.touched.address && formik.errors.address ? (
										<div class="invalid-feedback">{formik.errors.address}</div>
									) : null}
								</div>
							</div>
							<div className="col-md-6 mb-4">
								<div className="form-group">
									<label>Address: </label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="addressEnglish"
										value={staff.addressEnglish}
									/>
									{formik.touched.addressEnglish &&
									formik.errors.addressEnglish ? (
										<div class="invalid-feedback">
											{formik.errors.addressEnglish}
										</div>
									) : null}
								</div>
							</div>
							<div className="col-md-6 mb-4">
								<div className="form-group">
									<label>सम्पर्क: </label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="phoneNumber"
										value={staff.phoneNumber}
									/>
									{formik.touched.phoneNumber && formik.errors.phoneNumber ? (
										<div class="invalid-feedback">
											{formik.errors.phoneNumber}
										</div>
									) : null}
								</div>
							</div>
							<div className="col-md-6 mb-4">
								<div className="form-group">
									<label>Contact Number: </label>
									<input
										type="number"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="phoneNumberEnglish"
										value={staff.phoneNumberEnglish}
									/>
									{formik.touched.phoneNumberEnglish &&
									formik.errors.phoneNumberEnglish ? (
										<div class="invalid-feedback">
											{formik.errors.phoneNumberEnglish}
										</div>
									) : null}
								</div>
							</div>
							<div className="col-md-6 mb-4">
								<div class="form-group">
									<label>पद</label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="designation"
										value={staff.designation}
									/>
									{formik.touched.designation && formik.errors.designation ? (
										<div class="invalid-feedback">
											{formik.errors.designation}
										</div>
									) : null}
								</div>
							</div>
							<div className="col-md-6 mb-4">
								<div class="form-group">
									<label>Designation:</label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="designationEnglish"
										value={staff.designationEnglish}
									/>
									{formik.touched.designationEnglish &&
									formik.errors.designationEnglish ? (
										<div class="invalid-feedback">
											{formik.errors.designationEnglish}
										</div>
									) : null}
								</div>
							</div>
							<div className="col-md-6 mb-4">
								<div class="form-group">
									<label>शाखा</label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="sakha"
										value={staff.sakha}
									/>
									{formik.touched.sakha && formik.errors.sakha ? (
										<div class="invalid-feedback">{formik.errors.sakha}</div>
									) : null}
								</div>
							</div>
							<div className="col-md-6 mb-4">
								<div class="form-group">
									<label>Department</label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="sakhaEnglish"
										value={staff.sakhaEnglish}
									/>
									{formik.touched.sakhaEnglish && formik.errors.sakhaEnglish ? (
										<div class="invalid-feedback">
											{formik.errors.sakhaEnglish}
										</div>
									) : null}
								</div>
							</div>
							<div className="col-md-3 mb-4">
								<div class="form-group">
									<label>कार्य समय देखि</label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="workTimeSpanFrom"
										value={staff.workTimeSpanFrom}
									/>
									{/* <NepaliDatePicker
									inputClassName="form-control"
									className=""
									name="workTimeSpanFrom"
									value={staff.workTimeSpanFrom}
									onChange={formik.handleChange}
									options={{ calenderLocale: "ne", valueLocale: "en" }}
								/> */}
								</div>
							</div>

							<div className="col-md-3 mb-4">
								<div class="form-group">
									<label>कार्य समय सम्म</label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="workTimeSpanTo"
										value={staff.workTimeSpanTo}
									/>
									{/* <NepaliDatePicker
									inputClassName="form-control"
									className=""
									name="workTimeSpanTo"
									value={staff.workTimeSpanTo}
									onChange={formik.handleChange}
									options={{ calenderLocale: "ne", valueLocale: "en" }}
								/> */}
								</div>
							</div>

							<div className="col-md-6 mb-4">
								<div className="form-group">
									<label>ईमेल: </label>
									<input
										type="email"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="email"
										value={staff.email}
										style={{ fontFamily: "Arial" }}
									/>
									{formik.touched.email && formik.errors.email ? (
										<div class="invalid-feedback">{formik.errors.email}</div>
									) : null}
								</div>
							</div>

							<div className="col-lg-6">
								<div class="form-group">
									<label>घरको फोटो</label>
									{staff.uniqueIdentifier ? (
										<div className="row">
											<div className="col-12">
												<div className="image-to-upload p-3">
													<ImageUploader
														fileListContent={props.fileListContent}
														uniqueIdentifier={staff.uniqueIdentifier}
														imageName="staff"
													/>
													<img
														height="150"
														src={`https://bhimdatta.hamropalika.org/api/imageUpload/image/allImg/${staff.uniqueIdentifier +
															"_.jpg"}`}
														alt=""
													/>
												</div>
											</div>
										</div>
									) : (
										<ImageUploader
											fileListContent={props.fileListContent}
											uniqueIdentifier={staff.uniqueIdentifier}
											imageName="staff"
										/>
									)}
								</div>
							</div>

							{/* <div className="col-md-6 mb-4">
								<label className="form-group">लिङ्ग</label>
								<input
									type="text"
									className="form-control mr-3"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									name="gender"
									value={staff.gender}
								/>
								{formik.touched.gender && formik.errors.gender ? (
									<div class="invalid-feedback">{formik.errors.gender}</div>
								) : null}
							</div> */}
							<div className="col-12 d-flex justify-content-end">
								<Button
									variant="light-primary"
									className="mr-3"
									type="submit"
									disabled={formik.isSubmitting}
								>
									{formik.isSubmitting ? (
										<Spinner
											animation="grow"
											variant="primary"
											size="sm"
											className="mr-2"
										/>
									) : null}
									सच्यानुहोस
								</Button>
								<Button variant="light-danger" onClick={handleClose}>
									रद्द गर्नुहोस
								</Button>
							</div>
						</div>
					</form>
				)}
			</>
		);
	};
	return (
		<>
			<Modal
				centered
				show={props.show}
				onHide={handleClose}
				scrollable={true}
				dialogClassName="data-detail-modal"
				size="xl"
				style={{ zIndex: "15000" }}
			>
				<Modal.Header closeButton={true}>
					<Modal.Title>कर्मचारी थप्नुहोस्</Modal.Title>
				</Modal.Header>
				<Modal.Body>{getContent()}</Modal.Body>
			</Modal>
		</>
	);
};

export default EditStaff;
