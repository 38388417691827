import React from "react";
import Ayurvedic from "../survey-forms/Ayurvedic";
import Bank from "../survey-forms/Bank";
import PalikaOffice from "../survey-forms/PalikaOffice";
import CommunityOrg from "../survey-forms/CommunityOrg";
import FarmerSaving from "../survey-forms/FarmerSaving";
import Club from "../survey-forms/Club";
import CitizenAware from "../survey-forms/CitizenAware";
import CooperativeInstitute from "../survey-forms/CooperativeInstitute";
import Institution from "../survey-forms/Institution";
import RmFamily from "../survey-forms/RmFamily";
import RmStaff from "../survey-forms/RmStaff";
import SafeMaternity from "../survey-forms/SafeMaternity";
import FemaleChildHealth from "../survey-forms/FemaleChildHealth";
import PersonalIncident from "../survey-forms/PersonalIncident";
import Jungle from "../survey-forms/Jungle";
import Nikunja from "../survey-forms/Nikunja";
import GrazingField from "../survey-forms/GrazingField";
import DisasterInformation from "../survey-forms/DisasterInformation";
import DisasterEffect from "../survey-forms/DisasterEffect";
import DisasterOrg from "../survey-forms/DisasterOrg";
import EconomicResource from "../survey-forms/EconomicResource";
import SocialSecurity from "../survey-forms/SocialSecurity";
import SkilledDetail from "../survey-forms/SkilledDetail";
import FishFarm from "../survey-forms/FishFarm";
import Irrigation from "../survey-forms/Irrigation";
import AgricultureMarket from "../survey-forms/AgricultureMarket";
import GoatFarm from "../survey-forms/GoatFarm";
import CowFarm from "../survey-forms/CowFarm";
import Mill from "../survey-forms/Mill";
import ProductionTourism from "../survey-forms/ProductionTourism";
import Worker from "../survey-forms/Worker";
import BusinessInstitute from "../survey-forms/BusinessInstitute";
import TourismPlace from "../survey-forms/TourismPlace";
import Temple from "../survey-forms/Temple";
import Hotel from "../survey-forms/Hotel";
import NaturalDisaster from "../survey-forms/NaturalDisaster";
import Market from "../survey-forms/Market";
import School from "../survey-forms/School";
// import { School } from '@material-ui/icons';
import College from "../survey-forms/College";
import TechnicalEducation from "../survey-forms/TechnicalEducation";
import Hospital from "../survey-forms/Hospital";
import Medical from "../survey-forms/Medical";
import InsidePalikaDisease from "../survey-forms/InsidePalikaDisease";
import Ground from "../survey-forms/Ground";
import Sports from "../survey-forms/Sports";
import SocialResources from "../survey-forms/SocialResources";
import DisasterWater from "../survey-forms/DisasterWater";
import Waste from "../survey-forms/Waste";
import RoadNetwork from "../survey-forms/RoadNetwork";
import Pavement from "../survey-forms/Pavement";
import Bridge from "../survey-forms/Bridge";
import Quarter from "../survey-forms/Quarter";
import CommunityBuilding from "../survey-forms/CommunityBuilding";
import PublicToilet from "../survey-forms/PublicToilet";
import FreeWifi from "../survey-forms/FreeWifi";
import Electricity from "../survey-forms/Electricity";
import FmStation from "../survey-forms/FmStation";
import NewsPaper from "../survey-forms/NewsPaper";
import SabDaha from "../survey-forms/SabDaha";
import BusRoute from "../survey-forms/BusRoute";
import Festival from "../survey-forms/Festival";
import ChildHome from "../survey-forms/ChildHome";
import Helpless from "../survey-forms/Helpless";
import OldAgeHome from "../survey-forms/OldAgeHome";
import RehabCenter from "../survey-forms/RehabCenter";
import GenderCrime from "../survey-forms/GenderCrime";
import BusStation from "../survey-forms/BusStation";
import WomenSaving from "../survey-forms/WomenSaving";
import PhysicalResource from "../survey-forms/PhysicalResource";
import DisasterInfrastructure from "../survey-forms/DisasterInfrastructure";
import AnimalFarm from "../survey-forms/AnimalFarm";

export const Categories = [
	{
		id: 1,
		title: "खण्ड क - जनसंख्या विवरण",
		pageTitle: "जनसंख्या विवरण",
		icon: "/media/svg/hamropalika-icons/book.svg",
		subCategories: [
			{
				id: 1,
				title: "१.२ व्यक्तिगत घटना विवरण",
				component: PersonalIncident,
				route: "personalIncident",
			},
			{
				id: 2,
				title: "१.३ सामाजिक सुरक्षा कार्याक्रम विवरण",
				component: SocialSecurity,
				route: "socialSecurity",
			},
		],
	},
	{
		id: 2,
		title: "खण्ड ख - आर्थिक विकास स्थिति विवरण",
		pageTitle: "आर्थिक विकास स्थिति विवरण",
		icon: "/media/svg/hamropalika-icons/health.svg",
		subCategories: [
			{
				id: 3,
				title:
					"२.१ प्राविधिक, सीपयुक्त तथा विशेष दक्षता भएका मानव संसाधनको विवरण",
				component: SkilledDetail,
				route: "skilledManpower",
			},
			{
				id: 4,
				title: "२.२ सार्वजनिक पोखरी तथा माछापालन",
				component: FishFarm,
				route: "fishFarm",
			},
			{
				id: 5,
				title: "२.३ सिचाई सुबिधाको उपलब्धता",
				component: Irrigation,
				route: "irrigation",
			},
			{
				id: 6,
				title: "२.४.१ कृषि तथा पशु फार्म सम्बन्धी विवरण",
				component: AgricultureMarket,
				route: "agricultureMarket",
			},
			{
				id: 7,
				title: "२.४.२ खसी वा बाख्रा फर्म",
				component: GoatFarm,
				route: "goatFarm",
			},
			{
				id: 8,
				title: "२.४.३ गाई / भैसी फर्म",
				component: CowFarm,
				route: "cowFarm",
			},
			{
				id: 9,
				title: "२.५ कृषि तथा पशुसेवासँग सम्बन्धित मानव संसाधन(संख्या)",
				component: AnimalFarm,
				route: "animalFarm",
			},
			{
				id: 10,
				title: "२.६ घट्ट, मिल तथा संकलन तथा प्रसोधन सम्बन्धी विवरण",
				component: Mill,
				route: "mill",
			},
			{
				id: 11,
				title: "२.७ उत्पादन तथा सेवामूलक उधोग सम्बन्धी विवरण",
				component: ProductionTourism,
				route: "productionTourism",
			},
			{
				id: 12,
				title: "२.७.१ इँटा भट्टा वा ठुला उधोगमा कार्यरहत मजदुरहरुको विवरण",
				component: Worker,
				route: "worker",
			},
			// { id: 13, title: "२.८ व्यापार तथा व्यावसाय सम्बन्धी विवरण", component: null, route: ""},
			{
				id: 14,
				title: "२.८ व्यापार तथा व्यावसाय सम्बन्धी विवरण",
				component: BusinessInstitute,
				route: "businessInstitute",
			},
			{
				id: 15,
				title: "२.९ धार्मिक, एतिहासिक तथा पर्यटकीय स्थल सम्बन्धी विवरण",
				component: TourismPlace,
				route: "tourismPlace",
			},
			{
				id: 16,
				title:
					"२.९.१ पालिकामा अवस्थित मठ, मन्दिर, गुम्बा, चर्च, मस्जीद सम्बन्धी विवरण",
				component: Temple,
				route: "temple",
			},
			{
				id: 17,
				title: "२.१० होटेल, रिसोर्ट, रेस्टुरेन्ट र होमस्टे सम्बन्धी विवरण",
				component: Hotel,
				route: "hotel",
			},
			{
				id: 18,
				title: "२.११ कृषि बजार तथा हाट बजार सम्बन्धी विवरण",
				component: Market,
				route: "market",
			},
		],
	},
	{
		id: 3,
		title: "खण्ड ग : सामाजिक विकास",
		pageTitle: "सामाजिक विकास",
		icon: "/media/svg/hamropalika-icons/wildlife.svg",
		subCategories: [
			{ id: 19, title: "३.१ स्कुल", component: School, route: "school" },
			{ id: 20, title: "३.२ कलेज", component: College, route: "college" },
			{
				id: 21,
				title: "३.३ प्राविधिक शिक्षा",
				component: TechnicalEducation,
				route: "technicalEducation",
			},
			{ id: 22, title: "३.४ अस्पताल", component: Hospital, route: "hospital" },
			{
				id: 23,
				title: "३.५ स्वास्थ्य चौकी",
				component: Hospital,
				route: "healthPost",
			},
			{
				id: 24,
				title: "३.६ मेडिकल सम्बन्धि",
				component: Medical,
				route: "medical",
			},
			{
				id: 25,
				title:
					"३.७ पालिका भित्र देखा परेका प्रमुख रोग र तिनको उपचार सम्बन्धी विवरण",
				component: InsidePalikaDisease,
				route: "insidePalikaDisease",
			},
			{
				id: 26,
				title: "३.८ खोप सेवा तथा सुरक्षित मातृत्व सम्बन्धी विवरण",
				component: SafeMaternity,
				route: "safeMaternity",
			},
			{
				id: 27,
				title: "३.९ सुरक्षित मातृत्व सम्बन्धी विवरण",
				component: FemaleChildHealth,
				route: "femaleChildHealth",
			},
			{
				id: 28,
				title:
					"३.१० खेलमैदान,पार्क,पिकनिक स्थल तथा मनोरञ्जन स्थल सम्बन्धी विवरण",
				component: Ground,
				route: "ground",
			},
			{
				id: 29,
				title: "३.१२ व्यावसायिक खेलाडीको विवरण",
				component: Sports,
				route: "sports",
			},
			{
				id: 30,
				title: "३.१३ मुख्य चाड, पर्व तथा मेला जात्रा सम्बन्धी विवरण",
				component: Festival,
				route: "festival",
			},
			{
				id: 31,
				title: "३.१४ बालगृह सम्बन्धी विवरण",
				component: ChildHome,
				route: "childHome",
			},
			{
				id: 32,
				title: "३.१५ संचालित वृद्धाश्रम सम्बन्धी विवरण",
				component: OldAgeHome,
				route: "oldAgeHome",
			},
			{
				id: 33,
				title: "३.१६ संचालित पुन:संस्थापना केन्द्रको विवरण",
				component: RehabCenter,
				route: "rehabCenter",
			},
			{
				id: 34,
				title: "३.१७ वेवारिसे तथा असहाय नागरिकको विवरण",
				component: Helpless,
				route: "helpless",
			},
			{
				id: 35,
				title: "३.१८ लैंगिक हिँसा सम्बन्धी विवरण",
				component: GenderCrime,
				route: "genderCrime",
			},
		],
	},
	{
		id: 4,
		title: "खण्ड घ : भौतिक पूर्वाधार स्थिति विवरण",
		pageTitle: "भौतिक पूर्वाधार स्थिति विवरण",
		icon: "/media/svg/hamropalika-icons/farm.svg",
		subCategories: [
			{
				id: 36,
				title: "४.१ सडक मार्ग",
				component: RoadNetwork,
				route: "roadNetwork",
			},
			{
				id: 37,
				title: "४.२ पैदल मार्ग विवरण",
				component: Pavement,
				route: "pavement",
			},
			{
				id: 38,
				title: "४.३ पुल तथा पुलेसा",
				component: Bridge,
				route: "bridge",
			},
			{ id: 39, title: "४.४ सरकारी भवन", component: Quarter, route: "quarter" },
			{
				id: 40,
				title: "४.५ सार्वाजनिक तथा सामुदायिक भवन",
				component: CommunityBuilding,
				route: "communityBuilding",
			},
			{
				id: 41,
				title: "४.६ सार्वजनिक शौचालय",
				component: PublicToilet,
				route: "publicToilet",
			},
			{
				id: 42,
				title: "४.७ सार्वजनिक वाइफाई भएको स्थान",
				component: FreeWifi,
				route: "freeWifi",
			},
			{
				id: 43,
				title: "४.८ विधुत आयोजना सम्बन्धी विवरण",
				component: Electricity,
				route: "electricity",
			},
			{
				id: 44,
				title: "४.९ एफ एम / T. V सम्बन्धी विवरण",
				component: FmStation,
				route: "fmStation",
			},
			{
				id: 45,
				title: "४.१० पत्रपत्रिका सम्बन्धी विवरण",
				component: NewsPaper,
				route: "newsPaper",
			},
			{
				id: 46,
				title: "४.११ शवदाह स्थल सम्बन्धी विवरण",
				component: SabDaha,
				route: "sabDaha",
			},
			{
				id: 47,
				title: "४.१२ रुट परमिट लिएर संचालन भएका सवारी साधन",
				component: BusRoute,
				route: "busRoute",
			},
			{
				id: 48,
				title: "४.१३ वसपार्क तथा बस विसौनी सम्बन्धी विवरण",
				component: BusStation,
				route: "busStation",
			},
		],
	},
	{
		id: 5,
		title: "खण्ड ङ : वन, जैविक विविधता तथा वातावरण",
		pageTitle: "वन, जैविक विविधता तथा वातावरण",
		icon: "/media/svg/hamropalika-icons/security.svg",
		subCategories: [
			{ id: 49, title: "५.१ वनको विवरण", component: Jungle, route: "jungle" },
			{
				id: 50,
				title: "५.२ जडिबुटीको विवरण",
				component: Ayurvedic,
				route: "ayurvedic",
			},
			{
				id: 51,
				title: "५.३ राष्ट्रिय निकुन्ज, आरक्ष र मध्यवर्ती तथा संरक्षित क्षेत्र",
				component: Nikunja,
				route: "nikunja",
			},
			{
				id: 52,
				title: "५.४ चरण क्षेत्र",
				component: GrazingField,
				route: "grazingField",
			},
			{
				id: 53,
				title: "५.५ जोखिमयुक्त (संवेदनशील) स्थानमा वसोवास गरेका परिवार विवरण",
				component: DisasterInformation,
				route: "disasterInformation",
			},
			{
				id: 54,
				title: "५.६ विपदबाट मानवीय क्षती (विगत ५ वर्षको )",
				component: NaturalDisaster,
				route: "naturalDisaster",
			},
			{
				id: 55,
				title: "५.७ विपद असर विवरण",
				component: DisasterEffect,
				route: "disasterEffect",
			},
			{
				id: 56,
				title: "५.८ जोखिम पहिचान तथा क्षमता विश्लेषण (क)मानविय श्रोत",
				component: DisasterOrg,
				route: "disasterOrg",
			},
			{
				id: 57,
				title: "५.९ (ख) भौतिक श्रोत सम्बन्धी विवरण",
				component: PhysicalResource,
				route: "physicalResource",
			},
			{
				id: 58,
				title: "५.१० (ग) सामाजिक श्रोत तथा सामाग्रीको व्यवस्था",
				component: SocialResources,
				route: "socialResources",
			},
			{
				id: 59,
				title: "५.११ (घ) आर्थिक श्रोत सम्बन्धी",
				component: EconomicResource,
				route: "economicResource",
			},
			{
				id: 60,
				title: "५.१२ विपदबाट क्षति सार्वजनिक संरचना तथा पूर्वाधार",
				component: DisasterInfrastructure,
				route: "disasterInfrastructure",
			},
			{
				id: 61,
				title: "५.१३ संरक्षित पानिमुहान , जमिन र भौतिक संरचना",
				component: DisasterWater,
				route: "disasterWater",
			},
			{
				id: 62,
				title: "५.१४ फोहरमैला व्यवस्थापन सम्बन्धी",
				component: Waste,
				route: "waste",
			},
		],
	},
	{
		id: 6,
		title: "खण्ड च: संस्थागत विकास विवरण",
		pageTitle: "संस्थागत विकास विवरण",
		icon: "/media/svg/hamropalika-icons/child-club.svg",
		subCategories: [
			{
				id: 63,
				title:
					"६.१ गाउँपालिका/नगरपालिका,संघीय तथा प्रदेश कार्यालय तथा निकायहरु",
				component: PalikaOffice,
				route: "palikaOffice",
			},
			{
				id: 64,
				title: "६.२ सामुदायिक संस्था विवरण",
				component: CommunityOrg,
				route: "communityOrg",
			},
			{
				id: 65,
				title: "६.३ आमा, महिला समूह तथा परम्परागत समूहको विवरण",
				component: WomenSaving,
				route: "womenSaving",
			},
			{
				id: 66,
				title: "६.४ बाल, युवा, अपाङ्गता संजाल/क्लब सम्बधी विवरण",
				component: Club,
				route: "club",
			},
			{
				id: 67,
				title: "६.५ नागरिक सचेतना केन्द्र र टोल विकास संस्थाको विवरण",
				component: CitizenAware,
				route: "citizenAware",
			},
			{
				id: 68,
				title: "६.६ कृषक तथा उधमी तथा बचत समूहको विवरण",
				component: FarmerSaving,
				route: "farmerSaving",
			},
			{
				id: 69,
				title: "६.७ सुरक्षित मातृत्व सम्बन्धी विवरण",
				component: SafeMaternity,
				route: "safeMaternity",
			},
			{
				id: 70,
				title: "६.८ वैंक,वित्तिय संस्था तथा लघु वित्त सम्बन्धी विवरण",
				component: Bank,
				route: "bank",
			},
			{
				id: 71,
				title: "६.९ सहकारी संस्था सम्बन्धी विवरण",
				component: CooperativeInstitute,
				route: "cooperativeInstitute",
			},
			{
				id: 72,
				title: "६.१० गैर सरकारी संस्था सम्बन्धी विवरण",
				component: Institution,
				route: "institution",
			},
			{
				id: 73,
				title: "६.११ परिवारको विवरण",
				component: RmFamily,
				route: "rmFamily",
			},
			{
				id: 74,
				title: "६.१२ कर्मचारीहरुको विवरण",
				component: RmStaff,
				route: "rmStaff",
			},
		],
	},
	// {
	//     id: 7,
	//     title: "धार्मिक स्थल तथा चाडपर्व सम्बन्धि",
	//     icon: "/media/svg/hamropalika-icons/temple.svg"
	// },
	// {
	//     id: 8,
	//     title: "पर्यटन सम्बन्धि",
	//     icon: "/media/svg/hamropalika-icons/tourist.svg"
	// },
	// {
	//     id: 9,
	//     title: "संस्थागत",
	//     icon: "/media/svg/hamropalika-icons/organization.svg"
	// },
	// {
	//     id: 10,
	//     title: "भौतिक पूर्वाधार स्थिति",
	//     icon: "/media/svg/hamropalika-icons/infrastructure.svg"
	// },
	// {
	//     id: 11,
	//     title: "सर्वाजंक स्थल",
	//     icon: "/media/svg/hamropalika-icons/park.svg"
	// },
	// {
	//     id: 12,
	//     title: "बैंक तथा वित्तीय संस्था",
	//     icon: "/media/svg/hamropalika-icons/bank.svg"
	// },
	// {
	//     id: 13,
	//     title: "यातायात",
	//     icon: "/media/svg/hamropalika-icons/car.svg"
	// },
	// {
	//     id: 14,
	//     title: "युवा तथा खेलकुद",
	//     icon: "/media/svg/hamropalika-icons/sports.svg"
	// },
	// {
	//     id: 15,
	//     title: "विपद जोखिम तथा व्यवस्थापन",
	//     icon: "/media/svg/hamropalika-icons/disaster.svg"
	// },
	// {
	//     id: 16,
	//     title: "सामुदायिक तथा सरकारी भवन",
	//     icon: "/media/svg/hamropalika-icons/government.svg"
	// },
	// {
	//     id: 17,
	//     title: "व्यापार तथा व्यवसाय सम्बन्धी",
	//     icon: "/media/svg/hamropalika-icons/business.svg"
	// },
	// {
	//     id: 18,
	//     title: "स्रोत",
	//     icon: "/media/svg/hamropalika-icons/natural-resources.svg"
	// },
	// {
	//     id: 19,
	//     title: "संचार",
	//     icon: "/media/svg/hamropalika-icons/communication.svg"
	// },
	// {
	//     id: 20,
	//     title: "अन्य",
	//     icon: "/media/svg/hamropalika-icons/other.svg"
	// }
];
export const ngo = {
	nepali: "गैर सरकारी संस्था सम्बन्धी विवरण",
	english: "",
	route: "institution",
	count: 2,
};
export const police = {
	nepali: "सुरक्षा निकाय विवरण",
	english: "",
	route: "police",
	count: 2,
};
export const palikaOffice = {
	nepali: "गाउँपालिका/नगरपालिका,संघीय तथा प्रदेश कार्यालय तथा निकायहरु",
	english: "",
	route: "palikaOffice",
	count: 2,
};
export const bank = {
	nepali: "वैंक,वित्तिय संस्था तथा लघु वित्त सम्बन्धी विवरण",
	english: "",
	route: "bank",
	count: 2,
};
export const jungle = {
	nepali: "वनको विवरण ",
	english: "",
	route: "jungle",
	count: 2,
};
export const cooperativeInstitute = {
	nepali: "सहकारी संस्था सम्बन्धी विवरण  ",
	english: "",
	route: "cooperativeInstitute",
	count: 2,
};

export const citizenAwareness = {
	nepali: "नागरिक सचेतना केन्द्र र टोल विकास संस्थाको विवरण ",
	english: "",
	route: "citizenAware",
	count: 2,
};
export const farmerSaving = {
	nepali: "कृषक तथा उधमी तथा बचत समूहको विवरण ",
	english: "",
	route: "farmerSaving",
	count: 2,
};
export const socialResources = {
	nepali: "(ग) सामाजिक श्रोत तथा सामाग्रीको व्यवस्था",
	english: "",
	route: "socialResources",
	count: 2,
};
export const economicalResources = {
	nepali: "(घ) आर्थिक श्रोत सम्बन्धी",
	english: "",
	route: "economicalResources",
	count: 2,
};

export const wasteMgmt = {
	nepali: "फोहरमैला व्यवस्थापन सम्बन्धी",
	english: "",
	route: "waste",
	count: 2,
};
export const disasterOrganization = {
	nepali: "जोखिम पहिचान तथा क्षमता विश्लेषण (क)मानविय श्रोत",
	english: "",
	route: "disasterorg",
	count: 2,
};
export const disasterInfrastructure = {
	nepali: "विपदबाट क्षति सार्वजनिक संरचना तथा पूर्वाधार",
	english: "",
	route: "disasterInfrastructure",
	count: 2,
};
// export const disasterEffect = {
//     nepali: "विपद असर विवरण  ",
//     english: "",
//     route: "disasterEffect",
//     count: 2,
// };
export const disasterWaterResource = {
	nepali: "संरक्षित पानिमुहान , जमिन र भौतिक संरचना",
	english: "",
	route: "disasterWater",
	count: 2,
};
export const physicalresource = {
	nepali: "(ख) भौतिक श्रोत सम्बन्धी विवरण",
	english: "",
	route: "physicalresource",
	count: 2,
};
export const communityOrg = {
	nepali: "सामुदायिक संस्था विवरण ",
	english: "",
	route: "communityOrg",
	count: 2,
};
export const ayurvedic = {
	nepali: "जडिबुटीको विवरण  ",
	english: "",
	route: "ayurvedic",
	count: 2,
};
export const sports = {
	nepali: "व्यावसायिक खेलाडीको विवरण",
	english: "",
	route: "sports",
	count: 2,
};
export const communityHall = {
	nepali: "सार्वाजनिक तथा सामुदायिक भवन",
	english: "",
	route: "communityBuilding",
	count: 2,
};
export const quarter = {
	nepali: "सरकारी भवन",
	english: "",
	route: "quarter",
	count: 2,
};
export const club = {
	nepali: "बाल, युवा, अपाङ्गता संजाल/क्लब सम्बधी विवरण",
	english: "",
	route: "club",
	count: 2,
};

export const school = {
	nepali: "स्कुल",
	english: "",
	route: "school",
	count: 2,
	categoryId: 1,
};
export const college = {
	nepali: "कलेज",
	english: "",
	route: "college",
	count: 2,
	categoryId: 1,
};
export const technicalEducation = {
	nepali: "प्राविधिक शिक्षा",
	english: "",
	route: "technicalEducation",
	count: 2,
	categoryId: 1,
};
export const medical = {
	nepali: "मेडिकल सम्बन्धि",
	english: "",
	route: "medical",
	count: 2,
};
export const insidePalikaDisease = {
	nepali: "पालिका भित्र देखा परेका प्रमुख रोग र तिनको उपचार सम्बन्धी विवरण",
	english: "",
	route: "insidePalikaDisease",
	count: 2,
};
export const farmHr = {
	nepali: "२.५ कृषि तथा पशुसेवासँग सम्बन्धित मानव संसाधन (संख्या)",
	english: "",
	route: "farmHr",
	count: 2,
};

export const mill = {
	nepali: "२.६ घट्ट, मिल तथा संकलन तथा प्रसोधन सम्बन्धी विवरण",
	english: "",
	route: "mill",
	count: 2,
};

export const productionTourism = {
	nepali: "२.७ उत्पादन तथा सेवामूलक उधोग सम्बन्धी विवरण",
	english: "",
	route: "productionTourism",
	count: 2,
};
export const agricultureMarket = {
	nepali: "२.११ कृषि बजार तथा हाट बजार सम्बन्धी विवरण",
	english: "",
	route: "agricultureMarket",
	count: 2,
};
export const sabdaha = {
	nepali: "शवदाह स्थल सम्बन्धी विवरण",
	english: "",
	route: "sabdaha",
	count: 2,
};
export const disasterEffect = {
	nepali: "विपदको क्षेत्र अनुसार क्षति विवरण ",
	english: "",
	route: "disasterEffect",
	count: 2,
};

export const irrigation = {
	nepali: "२.३ सिचाई सुबिधाको उपलब्धता",
	english: "",
	route: "irrigation",
	count: 2,
};
export const worker = {
	nepali: "२.७.१ इँटा भट्टा वा ठुला उधोगमा कार्यरहत मजदुरहरुको विवरण",
	english: "",
	route: "worker",
	count: 2,
};
export const busRoute = {
	nepali: "रुट परमिट लिएर संचालन भएका सवारी साधन",
	english: "",
	route: "busRoute",
	count: 2,
};
export const busStation = {
	nepali: "वसपार्क तथा बस विसौनी सम्बन्धी विवरण",
	english: "",
	route: "busStation",
	count: 2,
};
export const goatFarm = {
	nepali: "२.४.२ खसी वा बाख्रा फर्म",
	english: "",
	route: "goatFarm",
	count: 2,
};

export const cowFarm = {
	nepali: "२.४.३ गाई /भैसी फर्म   ",
	english: "",
	route: "cowFarm",
	count: 2,
};
export const businessInstitute = {
	nepali: "२.८ व्यापार तथा व्यावसाय सम्बन्धी विवरण",
	english: "",
	route: "businessInstitute",
	count: 2,
};

export const personalIncident = {
	nepali: "१.२ व्यक्तिगत घटना विवरण",
	english: "",
	route: "personalIncident",
	count: 2,
};
export const drinkingWater = {
	nepali: "खानेपानी टयंकी सम्नन्धि",
	english: "",
	route: "drinkingWater",
	count: 2,
};
export const socialSecurity = {
	nepali: "१.३ सामाजिक सुरक्षा कार्याक्रम विवरण",
	english: "",
	route: "socialSecurity",
	count: 2,
};
export const fishFarm = {
	nepali: "२.२ सार्वजनिक पोखरी तथा माछापालन",
	english: "",
	route: "fishFarm",
	count: 2,
};

export const trainningInstitute = {
	nepali: "तालिम केन्द्र",
	english: "",
	route: "trainningInstitute",
	count: 2,
};

export const prodEducation = {
	nepali: "प्रोड्शिक्षा",
	english: "",
	route: "prodEducation",
	count: 2,
};

export const touristPlace = {
	nepali: "२.९ धार्मिक, एतिहासिक तथा पर्यटकीय स्थल सम्बन्धी विवरण",
	english: "",
	route: "tourismPlace",
	count: 2,
};

export const park = {
	nepali: "पार्क",
	english: "",
	route: "park",
	count: 2,
};

export const hotel = {
	nepali: "२.१० होटेल, रिसोर्ट , रेस्टुरेन्ट र होमस्टे सम्बन्धी विवरण",
	english: "",
	route: "hotel",
	count: 2,
};

export const mine = {
	nepali: "खानी",
	english: "",
	route: "mine",
	count: 2,
};
export const forest = {
	nepali: "सामुदायिक वन",
	english: "",
	route: "forest",
	count: 2,
};
export const nikunja = {
	nepali: "राष्ट्रिय निकुन्ज, आरक्ष र मध्यवर्ती तथा संरक्षित क्षेत्र",
	english: "",
	route: "nikunja",
	count: 2,
};
export const bridge = {
	nepali: "पुल तथा पुलेसा",
	english: "",
	route: "bridge",
	count: 2,
};
export const ground = {
	nepali: "खेलमैदान,पार्क,पिकनिक स्थल तथा मनोरञ्जन स्थल सम्बन्धी विवरण",
	english: "",
	route: "ground",
	count: 2,
};
export const market = {
	nepali: "हाट बजार मार्केट",
	english: "",
	route: "market",
	count: 2,
};
export const flora = {
	nepali: "जडीबुटी तथा वनस्पती",
	english: "",
	route: "herbs",
	count: 2,
};
export const cableCar = {
	nepali: "केबलकार",
	english: "",
	route: "cableCar",
	count: 2,
};
export const tuin = {
	nepali: "तुइन भएको खोला/नदी",
	english: "",
	route: "tuin",
	count: 2,
};
export const mainRiver = {
	nepali: "प्रमुख खोला/नदी",
	english: "",
	route: "mainRiver",
	count: 2,
};

export const communication = {
	nepali: "संचार टावर",
	english: "",
	route: "tower",
	count: 2,
};

export const fm = {
	nepali: "एफ एम / T. V सम्बन्धी विवरण",
	english: "",
	route: "fmStation",
	count: 2,
};

export const newspaper = {
	nepali: "पत्रपत्रिका सम्बन्धी विवरण",
	english: "",
	route: "newspaper",
	count: 2,
};
export const cableNetwork = {
	nepali: "केवल नेटवर्क",
	english: "",
	route: "cableNetwork",
	count: 2,
};
export const temple = {
	nepali:
		"२.९.१ पालिकामा अवस्थित मठ, मन्दिर, गुम्बा,चर्च, मस्जीद सम्बन्धी विवरण",
	english: "",
	route: "temple",
	count: 2,
};
export const mainBusiness = {
	nepali: "उद्योग तथा कलकारखाना",
	english: "",
	route: "mainBusiness",
	count: 2,
};
export const festival = {
	nepali: "मुख्य चाड, पर्व तथा मेला जात्रा सम्बन्धी विवरण",
	english: "",
	route: "festival",
	count: 2,
};

export const busPark = {
	nepali: "मुख्य बस टर्मिनल",
	english: "",
	route: "busPark",
	count: 2,
};
export const publicToilet = {
	nepali: "सार्वजनिक शौचालय",
	english: "",
	route: "publicToilet",
	count: 2,
};
export const roadNetwork = {
	nepali: "सडक मार्ग",
	english: "",
	route: "roadNetwork",
	count: 2,
};
export const freeWifi = {
	nepali: "सार्वजनिक वाइफाई भएको स्थान",
	english: "",
	route: "freeWifi",
	count: 2,
};

export const hospital = {
	nepali: "अस्पताल",
	english: "",
	route: "hospital",
	count: 2,
};
export const healthPost = {
	nepali: "स्वास्थ्य चौकी",
	english: "",
	route: "healthPost",
	count: 2,
};
// export const animalVeterinary = {
// 	nepali: "पशु (भेटेनरी)/उपचार केन्द्र",
// 	english: "",
// 	route: "animalVeterinary",
// 	count: 2,
//
// };
export const disasterInformation = {
	nepali: "जोखिमयुक्त (संवेदनशील) स्थानमा वसोवास गरेका परिवार विवरण",
	english: "",
	route: "disasterInformation",
	count: 2,
};
export const naturalDisaster = {
	nepali: "विपदबाट मानवीय क्षती (विगत ५ वर्षको )",
	english: "",
	route: "naturalDisaster",
	count: 2,
};
export const animal = {
	nepali: "जनावर तथा चराचुरुङ्गीहरुको बिबरण",
	english: "",
	route: "animal",
	count: 2,
};
export const grazingField = {
	nepali: "चरण क्षेत्र",
	english: "",
	route: "grazingField",
	count: 2,
};
export const animalFarm = {
	nepali: "२.४.१ कृषि तथा पशु फार्म सम्बन्धी विवरण",
	english: "",
	route: "animalFarm",
	count: 2,
};
export const serviceCenter = {
	nepali: "मुख्य मेकानिकल वर्कसप",
	english: "",
	route: "serviceCenter",
	count: 2,
};
export const villageCouncilFamily = {
	nepali: "परिवारको विवरण",
	english: "",
	route: "rmFamily",
	count: 2,
};
export const villageCounilStaff = {
	nepali: "कर्मचारीहरुको विवरण",
	english: "",
	route: "rmStaff",
	count: 2,
};
export const powerHouse = {
	nepali: "विधुत आयोजना सम्बन्धी विवरण",
	english: "",
	route: "electricity",
	count: 2,
};
export const transmissionLine = {
	nepali: "विद्युतीय ट्रान्समिसन लाईन",
	english: "",
	route: "transmissionLine",
	count: 2,
};
export const childHome = {
	nepali: "बालगृह सम्बन्धी विवरण",
	english: "",
	route: "childHome",
	count: 2,
};
export const oldAgeHome = {
	nepali: "संचालित वृद्धाश्रम सम्बन्धी विवरण",
	english: "",
	route: "oldAgeHome",
	count: 2,
};
export const rehabCenter = {
	nepali: "संचालित पुन:संस्थापना केन्द्रको विवरण",
	english: "",
	route: "rehabCenter",
	count: 2,
};
export const helpless = {
	nepali: "वेवारिसे तथा असहाय नागरिकको विवरण",
	english: "",
	route: "helpless",
	count: 2,
};
export const genderCrime = {
	nepali: "लैंगिक हिँसा सम्बन्धी विवरण",
	english: "",
	route: "genderCrime",
	count: 2,
};
export const pavement = {
	nepali: "पैदल मार्ग विवरण",
	english: "",
	route: "pavement",
	count: 2,
};
export const skilledManpower = {
	nepali: "२.१ प्राविधिक, सीपयुक्त तथा विशेष दक्षता भएका मानव संसाधनको विवरण",
	english: "",
	route: "skilledManpower",
	count: 2,
};

export const womenSaving = {
	nepali: "आमा, महिला समूह तथा परम्परागत समूहको विवरण",
	english: "",
	route: "womenSaving",
	count: 2,
};

export const safeMaternity = {
	nepali: "खोप सेवा तथा सुरक्षित मातृत्व सम्बन्धी विवरण",
	english: "",
	route: "safeMaternity",
	count: 2,
};

export const femaleChildHealth = {
	nepali: "सुरक्षित मातृत्व सम्बन्धी विवरण",
	english: "",
	route: "femaleChildHealth",
	count: 2,
};

// can rearrange list from here
export const CategoryList = [
	// {
	//     title: {
	//         english: "1. Population Detail ",
	//         nepali: "खण्ड क - जनसंख्या विवरण  ",
	//     },
	//     child: [personalIncident, socialSecurity],
	// },
	// {
	//     title: {
	//         english: "21. Economic Development",
	//         nepali: "खण्ड ख - आर्थिक विकास स्थिति विवरण",
	//     },
	//     child: [
	//         skilledManpower,
	//         fishFarm,
	//         irrigation,
	//         animalFarm,
	//         goatFarm,
	//         cowFarm,
	//         farmHr,
	//         mill,
	//         productionTourism,
	//         worker,
	//         businessInstitute,
	//         touristPlace,
	//         temple,
	//         hotel,
	//         agricultureMarket,
	//     ],
	// },
	{
		title: { english: "1. Education", nepali: "खण्ड ग : सामाजिक विकास" },
		child: [
			school,
			college,
			technicalEducation,
			hospital,
			healthPost,
			medical,
			insidePalikaDisease,
			safeMaternity,
			femaleChildHealth,
			ground,
			sports,
			childHome,
			oldAgeHome,
			rehabCenter,
			helpless,
			genderCrime,
		],
		icon: "/media/svg/hamropalika-icons/book.svg",
	},
	{
		title: { english: "2. Health Related", nepali: "स्वास्थ्य संस्था विवरण" },
		// child: [hospital, healthPost, animalVeterinary],
		child: [],
		icon: "/media/svg/hamropalika-icons/health.svg",
	},
	{
		title: { nepali: "खण्ड घ : भौतिक पूर्वाधार स्थिति विवरण", english: "" },
		child: [
			roadNetwork,
			pavement,
			bridge,
			quarter,
			communityHall,
			publicToilet,
			freeWifi,
			powerHouse,
			fm,
			newspaper,
			sabdaha,
			busRoute,
			busStation,
		],
	},
	{
		title: { nepali: "खण्ड ङ : वन, जैविक विविधता तथा वातावरण", english: "" },
		child: [
			jungle,
			ayurvedic,
			nikunja,
			grazingField,
			disasterInformation,
			naturalDisaster,
			disasterEffect,
			disasterOrganization,
			physicalresource,
			socialResources,
			economicalResources,
			disasterInfrastructure,
			disasterWaterResource,
			wasteMgmt,
		],
	},
	{
		title: { nepali: "खण्ड च: संस्थागत विकास विवरण", english: "" },
		child: [
			palikaOffice,
			communityOrg,
			womenSaving,
			club,
			citizenAwareness,
			farmerSaving,
			bank,
			cooperativeInstitute,
			ngo,
		],
	},
	{
		title: {
			english: "3. Forest and animal area",
			nepali: "३. वन तथा बन्यजन्तु आरक्षण क्षेत्र",
		},
		child: [nikunja, forest, animal, grazingField, flora, jungle, ayurvedic],
		icon: "/media/svg/hamropalika-icons/wildlife.svg",
	},
	{
		title: { english: "5. Policemen", nepali: "५. सुरक्षा निकाय सम्बन्धि" },
		child: [police],
	},
	{
		title: { english: "6. Club", nepali: "६. क्लब तथा सामुहिक विवरण" },
		child: [club],
	},
	{
		title: {
			english: "7. Temple",
			nepali: "७. धार्मिक स्थल तथा चार्डपर्व सम्बन्धि",
		},
		child: [festival],
	},
	{
		title: {
			english: "9. Institute",
			nepali: "९. संस्थागत विवरण",
		},
		child: [
			ngo,
			citizenAwareness,
			farmerSaving,
			communityOrg,
			cooperativeInstitute,
			palikaOffice,
			womenSaving,
		],
	},
	{
		title: {
			english: "10. Resources",
			nepali: "१०. भौतिक पूर्बाधार स्थिति विवरण",
		},
		child: [
			cableCar,
			tuin,
			bridge,
			powerHouse,
			transmissionLine,
			communication,
			market,
			mainRiver,
			fm,
			newspaper,
			cableNetwork,
			sabdaha,
		],
	},
	{
		title: { english: "11. Public Place", nepali: "११. सार्वजनिक स्थल" },
		child: [park, publicToilet, freeWifi],
	},
	{
		title: { english: "12. Bank", nepali: "१२. बैंक तथा वित्तीय संस्था" },
		child: [bank],
	},
	{
		title: { english: "13. Road Network", nepali: "१३. यातायात" },
		child: [busPark, roadNetwork, pavement, busRoute, busStation],
	},
	{
		title: { english: "14. GROUND", nepali: "१४. युवा तथा खेलकुद" },
		child: [ground, sports],
	},
	{
		title: {
			english: "15. Natural Disaster",
			nepali: "१५. विपद जोखिम तथा ब्यबस्थापन",
		},
		child: [
			disasterInformation,
			naturalDisaster,
			disasterEffect,
			disasterOrganization,
			socialResources,
			economicalResources,
			wasteMgmt,
			disasterInfrastructure,
			disasterWaterResource,
			physicalresource,
		],
	},
	{
		title: {
			english: "16. Community Building",
			nepali: "१६. सामुदायिक तथा सरकारी भवन",
		},
		child: [communityHall, quarter],
	},
	{
		title: {
			english: "17. Project",
			nepali: "१७. गाउँपालिकाको कर्मचारी तथा परिवार विवरण",
		},
		child: [villageCouncilFamily, villageCounilStaff],
	},
	{
		title: {
			english: "18. Business",
			nepali: "१८. व्यापार तथा व्यवसाय सम्बन्धी",
		},
		child: [mainBusiness],
	},
	{
		title: {
			english: "20. Drinking Water Detail",
			nepali: "२०. खानेपानी टयंकी सम्नन्धि",
		},
		child: [drinkingWater],
	},
	{
		title: {
			english: "23. Women, children and disable",
			nepali:
				"२३. महिला, बालबालिका, अपाङ्ग भएका तथा लक्षित वर्ग सम्बन्धी विवरण",
		},
		child: [childHome, oldAgeHome, rehabCenter, helpless, genderCrime],
	},
	{
		title: { english: "23. Others", nepali: "२३. अन्य" },
		child: [serviceCenter, mine],
	},
]; //survey ko card display garauda
