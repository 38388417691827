import { Button, Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { data } from "jquery";
import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { genderList } from "../../data/advanceSearch";
import { selectedLanguage } from "../../data/institute";
import {
	fetchUserDetails,
	saveUserDetails,
} from "../../modules/Auth/_redux/authCrud";
import Loader from "../Loader";
import * as Yup from "yup";

const defaultValues = {
	email: "",
	firstname: "",
	gender: "",
	id: "",
	lastname: "",
	username: "",
	wardRole: null,
	privilege: null,
};
const EditProfileSchema = Yup.object().shape({
	email: Yup.string()
		.email("ईमेल गलत छ")
		.required("इमेल अनिवार्य छ "),
	firstname: Yup.string().required("नाम अनिवार्य छ"),
	lastname: Yup.string().required("थर अनिवार्य छ"),
	username: Yup.string().required("प्रयोगकर्ता नाम अनिवार्य छ"),
});
const EditProfile = (props) => {
	const [userIdToEdit, SetuserIdToEdit] = useState("");
	const [initialValues, setInitialValues] = useState(defaultValues);
	const [loading, setLoading] = useState(true);
	const { authToken } = useSelector(
		({ auth }) => ({
			authToken: auth?.token,
		}),
		shallowEqual
	);
	useEffect(() => {
		if (!props.id) {
			let token = authToken?.split("Bearer: ")[0];
			if (token) {
				let decodedToken = jwtDecode(token);
				SetuserIdToEdit(decodedToken.id);
			}
		} else {
			SetuserIdToEdit(props.id);
		}
	}, []);
	useEffect(() => {
		if (props.show) {
			setLoading(true);
			fetchUserDetails(userIdToEdit)
				.then((res) => {
					setLoading(false);
					const data = res.data.pri.user;
					const userDetails = {
						email: data.email,
						firstname: data.name.first,
						gender: data.gender,
						id: userIdToEdit,
						lastname: data.name.last,
						username: data.username,
						wardRole: res.data.pri.ward,
						privilege: res.data.pri.role,
					};
					setInitialValues(userDetails);
				})
				.catch((err) => {
					setLoading(false);
					toast.error("An error occured while fetching user data");
				});
		}
	}, [userIdToEdit, props.show]);
	const formik = useFormik({
		initialValues,
		validationSchema: EditProfileSchema,
		enableReinitialize: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setSubmitting(true);
			saveUserDetails(values)
				.then((res) => {
					setSubmitting(false);
					if (res.data.success) {
						toast.success("User details updated successfully");
					} else {
						toast.error("An error occured");
					}
				})
				.catch((err) => {
					setSubmitting(false);
					toast.error("An error occured while fetching saving data");
				});
		},
	});
	const handleClose = () => props.setShow(false);
	const getContent = () => {
		const user = formik.values;
		return (
			<>
				{loading && <Loader loading={loading} />}
				{!loading && (
					<form onSubmit={formik.handleSubmit}>
						<div className="row">
							<div className="col-md-6 mb-4">
								<div className="form-group">
									<label>ईमेल: </label>
									<input
										type="email"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="email"
										value={user.email}
									/>
									{formik.touched.email && formik.errors.email ? (
										<div class="invalid-feedback">{formik.errors.email}</div>
									) : null}
								</div>
							</div>
							<div className="col-md-6 mb-4">
								<div className="form-group">
									<label>प्रयोगकर्ता नाम: </label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="username"
										value={user.username}
									/>
									{formik.touched.username && formik.errors.username ? (
										<div class="invalid-feedback">{formik.errors.username}</div>
									) : null}
								</div>
							</div>
							<div className="col-md-6 mb-4">
								<div className="form-group">
									<label>नाम: </label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="firstname"
										value={user.firstname}
									/>
									{formik.touched.firstname && formik.errors.firstname ? (
										<div class="invalid-feedback">
											{formik.errors.firstname}
										</div>
									) : null}
								</div>
							</div>
							<div className="col-md-6 mb-4">
								<div className="form-group">
									<label>थर: </label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="lastname"
										value={user.lastname}
									/>
									{formik.touched.lastname && formik.errors.lastname ? (
										<div class="invalid-feedback">{formik.errors.lastname}</div>
									) : null}
								</div>
							</div>
							<div className="col-12">
								<div class="form-group">
									<label>लिङ्ग</label>
									<div class="radio-inline">
										{genderList.map((value, i) => (
											<label class="radio radio-rounded">
												<input
													type="radio"
													name={`gender`}
													value={value.value}
													checked={value.value === user.gender}
													onBlur={formik.handleBlur}
													onChange={formik.handleChange}
												/>
												<span></span> {value[selectedLanguage]}
											</label>
										))}
									</div>
								</div>
							</div>
							<div className="col-12 d-flex justify-content-end">
								<Button
									variant="light-primary"
									className="mr-3"
									type="submit"
									disabled={formik.isSubmitting}
								>
									{formik.isSubmitting ? (
										<Spinner
											animation="grow"
											variant="primary"
											size="sm"
											className="mr-2"
										/>
									) : null}
									सच्यानुहोस
								</Button>
								<Button variant="light-danger" onClick={handleClose}>
									रद्द गर्नुहोस
								</Button>
							</div>
						</div>
					</form>
				)}
			</>
		);
	};
	return (
		<>
			<Modal
				centered
				show={props.show}
				onHide={handleClose}
				scrollable={true}
				dialogClassName="data-detail-modal"
				size="xl"
				style={{ zIndex: "15000" }}
			>
				<Modal.Header closeButton={true}>
					<Modal.Title>प्रयोगकर्ता सच्यानुहोस</Modal.Title>
				</Modal.Header>
				<Modal.Body>{getContent()}</Modal.Body>
			</Modal>
		</>
	);
};

export default EditProfile;
